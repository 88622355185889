<template>
  <div
    :class="{
      'form-control': true,
      error: hasError,
      focus: isFocus,
      'no-border': noBorder,
    }"
  >
    <a-row>
      <a-col :span="col">
        <div class="label" v-if="showTitle">{{ title }}</div>
      </a-col>
      <a-col :span="24 - col">
        <div class="input">
          <input
            ref="input"
            v-model="inputValue"
            @input="onChange"
            @focus="onFocus"
            @blur="onBlur"
            :disabled="disabled"
            :type="type"
            :placeholder="'请输入' + title"
          />
          <span v-if="isMust" class="must">*</span>
        </div>
      </a-col>
    </a-row>

    <div class="validate">
      <span v-if="hasError" class="error">{{ validate.message }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: String
    },

    title: {
      type: String,
      default: '标题'
    },

    col: {
      type: Number,
      default: 4
    },

    validate: {
      type: Object,
      default: () => null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    noBorder: {
      type: Boolean,
      default: false
    },

    showTitle: {
      type: Boolean,
      default: true
    },

    type: {
      type: String,
      default: 'text'
    }
  },
  emits: ['ok', 'update:value'],
  setup (props, { emit }) {
    const inputValue = ref(props.value)
    const isInput = ref(false)
    const isFocus = ref(false)

    watch(props, (e) => {
      inputValue.value = e.value
    })

    const onChange = (e) => {
      isInput.value = !!e.target.value
      emit('ok', e.target.value)
      emit('update:value', e.target.value)
    }

    const onFocus = () => {
      isFocus.value = true
    }

    const onBlur = () => {
      isFocus.value = false
    }

    return {
      inputValue,
      isInput,
      isFocus,
      onChange,
      onFocus,
      onBlur
    }
  },

  computed: {
    isMust () {
      return this.validate !== null
    },

    hasError () {
      return this.validate && this.validate.error && !this.isInput
    }
  }
})
</script>

<style lang="less" scoped>
@textHeight: 0.85rem;
@validateHeight: 0.4rem;
@errorColor: #f50;
@blurColor: rgb(0, 110, 255);
.form-control {
  border-bottom: 1px solid #dcdcdc;
  position: relative;
  margin-bottom: @validateHeight;
  .label {
    line-height: @textHeight;
    &:after {
      content: ":";
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
  }
  .input {
    position: relative;
    padding-right: 10px;
    height: @textHeight;
    input {
      display: block;
      height: 100%;
      width: 100%;
      border: none;
      background: none;
      text-align: right;
      outline: none;
    }
    .must {
      position: absolute;
      top: 0;
      right: 0;
      color: #f00;
      font-size: 18px;
      line-height: @textHeight;
    }
  }

  .validate {
    position: absolute;
    bottom: -@validateHeight;
    line-height: @validateHeight;
    color: #999;
    font-size: 0.12px;
    .error {
      color: @errorColor;
    }
  }

  &.error {
    border-color: @errorColor !important;
  }

  &.focus {
    border-color: @blurColor;
  }

  &.no-border {
    border: none;
    margin-bottom: 0;
  }
}
</style>

<template>
  <div
    :class="{
      'form-control': true,
      error: hasError,
      focus: isFocus,
    }"
  >
    <a-row>
      <a-col :span="col">
        <div class="label">{{ title }}</div>
      </a-col>
      <a-col :span="24 - col">
        <div class="input">
          <input
            ref="input"
            v-model="inputValue"
            @click="onShowDateTime"
            @focus="onFocus"
            @blur="onBlur"
            :placeholder="'请选择' + title"
            readonly
          />
          <span v-if="isMust" class="must">*</span>
        </div>
      </a-col>
    </a-row>

    <div class="validate">
      <span v-if="hasError" class="error">{{ validate.message }}</span>
    </div>

    <van-popup v-model:show="show" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        :min-date="minDate"
        type="datetime"
        :title="'请选择' + title"
        :filter="filter"
        @confirm="onChange"
      />
    </van-popup>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { DatetimePicker, Popup } from 'vant'
import 'vant/es/datetime-picker/style'
import 'vant/es/popup/style'
import dayjs from 'dayjs'

export default defineComponent({
  props: {
    value: {
      type: [String, Number]
    },

    title: {
      type: String,
      default: '标题'
    },

    col: {
      type: Number,
      default: 4
    },

    validate: {
      type: Object,
      default: () => null
    }
  },
  components: {
    [DatetimePicker.name]: DatetimePicker,
    [Popup.name]: Popup
  },
  emits: ['ok', 'update:value'],
  setup (props, { emit }) {
    const inputValue = ref(props.value)
    const isInput = ref(false)
    const isFocus = ref(false)

    const currentDate = ref(new Date(dayjs(props.value).format()))
    const minDate = ref(new Date())
    const show = ref(false)

    watch(props, (e) => {
      inputValue.value = e.value
    })

    const onChange = (e) => {
      show.value = false
      inputValue.value = dayjs(e).format('YYYY-MM-DD HH:mm')
      emit('ok', dayjs(e).format())
      emit('update:value', dayjs(e).format())
    }

    const onFocus = () => {
      isFocus.value = true
    }

    const onBlur = () => {
      isFocus.value = false
    }

    const onShowDateTime = () => {
      show.value = true
    }

    const filter = (type, options) => {
      if (type === 'minute') {
        return [0, 30]
      }
      return options
    }

    return {
      inputValue,
      isInput,
      isFocus,
      currentDate,
      show,
      minDate,
      onChange,
      onFocus,
      onBlur,
      onShowDateTime,
      filter
    }
  },

  computed: {
    isMust () {
      return true
    },

    hasError () {
      return this.validate.error && !this.isInput
    }
  }
})
</script>

<style lang="less" scoped>
@textHeight: 0.85rem;
@validateHeight: 0.4rem;
@errorColor: #f50;
@blurColor: rgb(0, 110, 255);
.form-control {
  border-bottom: 1px solid #dcdcdc;
  position: relative;
  margin-bottom: @validateHeight;
  .label {
    line-height: @textHeight;
    &:after {
      content: ":";
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
  }
  .input {
    position: relative;
    padding-right: 10px;
    height: @textHeight;
    input {
      display: block;
      height: 100%;
      width: 100%;
      border: none;
      background: none;
      text-align: right;
      outline: none;
    }
    .must {
      position: absolute;
      top: 0;
      right: 0;
      color: #f00;
      font-size: 18px;
      line-height: @textHeight;
    }
  }

  .validate {
    position: absolute;
    bottom: -@validateHeight;
    line-height: @validateHeight;
    color: #999;
    font-size: 0.12px;
    .error {
      color: @errorColor;
    }
  }

  &.error {
    border-color: @errorColor !important;
  }

  &.focus {
    border-color: @blurColor;
  }
}
</style>
